import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-96e306da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "not-found" }
const _hoisted_2 = { class: "not-found-container has-background-contents shadow" }
const _hoisted_3 = { class: "not-found-body" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "not-found-search" }
const _hoisted_6 = { class: "title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextLinkWithArrow = _resolveComponent("TextLinkWithArrow")!
  const _component_ItemKeywordSearch = _resolveComponent("ItemKeywordSearch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('message.notFound')), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.references')), 1),
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, [
            _createVNode(_component_TextLinkWithArrow, {
              to: { name: 'Help' },
              class: "level-left"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('header.help')), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("li", null, [
            _createVNode(_component_TextLinkWithArrow, {
              to: _ctx.faqLink,
              class: "level-left"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('label.faq')), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('message.searchItems')), 1),
        _createVNode(_component_ItemKeywordSearch)
      ])
    ])
  ]))
}