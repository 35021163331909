
import { defineComponent, computed } from 'vue'
import ItemKeywordSearch from '@/components/organisms/ItemKeywordSearch.vue'
import { useRoute } from 'vue-router'
import TextLinkWithArrow from '@/components/molecules/TextLinkWithArrow.vue'

export default defineComponent({
  components: {
    ItemKeywordSearch,
    TextLinkWithArrow,
  },
  setup () {
    const route = useRoute()
    const isRekion = computed(() => route.meta.isRekion)
    const faqLink = computed(() => isRekion.value ? '/rekion_faq' : '/FAQ')
    return {
      isRekion,
      faqLink,
    }
  },
})
