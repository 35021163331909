
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import RekionAdminMenu from '@/components/organisms/rekion/RekionAdminMenu.vue'
import NotFound from '@/components/static/NotFound.vue'
export default defineComponent({
  components: {
    RekionAdminMenu,
    NotFound,
  },
  setup () {
    const store = useStore()
    const isRekionAdmin = computed(() => store.getters.loggedInUser?.role?.includes('REKION_ADMIN'))
    return {
      isRekionAdmin,
    }
  },
})
