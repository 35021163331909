
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import AppButton from '../../atoms/AppButton.vue'
import TextAlert from '@/components/molecules/TextAlert.vue'
import { getData } from '@/helpers/util/webApiUtil'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    AppButton,
    TextAlert,
  },
  setup () {
    const i18n = useI18n()
    const router = useRouter()
    var isError = ref(false)

    const downloadLoginTool = async () => {
      isError.value = false
      const data = await createLoginTool()
      if (!data) {
        isError.value = true
      } else {
        const blob = new Blob([data], { type: 'text/html' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        // link.href = 'rekion_login_tool.html'
        link.download = 'rekion_login_tool.html'
        link.click()
      }
    }

    const createLoginTool = async () => {
      const baseUrl = process.env.VUE_APP_BASE_URL
      const baseApiUrl = process.env.VUE_APP_API_BASE_URL
      const response = await getData(`${baseApiUrl}/auth/associated`)
      if (!response) {
        return
      }
      let data = '<html>\n<body>\n<h2>' + i18n.t('label.screenTransitioning') + '</h2>\n</body>\n<footer>\n<script>\n'
      data += `var cardId = '${Object.keys(response)[0]}'\n`
      data += `var password = '${Object.values(response)[0]}'\n`
      data += 'window.location.href = `'
      data += `${baseUrl}/tool/login`
      data += '?cardId=${cardId}&password=${password}`\n' // eslint-disable-line no-template-curly-in-string
      data += '</' + 'script>\n</footer>\n</html>\n' // "+"を入れないとlint error
      return data
    }

    return {
      downloadLoginTool,
      isError,
      toStatistics: () => router.push('/statistics'),
      back: () => router.push('/'),
    }
  },
})
