import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a091e904"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rekion-admin-menu" }
const _hoisted_2 = { class: "rekion-admin-menu-header" }
const _hoisted_3 = { class: "has-text-contents-title has-text-title" }
const _hoisted_4 = { class: "rekion-admin-menu-body has-background-contents" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextAlert = _resolveComponent("TextAlert")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('label.rekionAdminMenu')), 1)
    ]),
    _createElementVNode("body", _hoisted_4, [
      (_ctx.isError)
        ? (_openBlock(), _createBlock(_component_TextAlert, {
            key: 0,
            id: "error-message",
            class: "error-message"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('parts.rekionAdminToolErrorMsg')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_AppButton, {
        id: "login-tool-dl",
        class: "is-accent-sub",
        onClick: _ctx.downloadLoginTool,
        onKeydown: _withKeys(_withModifiers(_ctx.downloadLoginTool, ["prevent"]), ["space","enter"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('label.rekionAdminDownloadLoginTool')), 1)
        ]),
        _: 1
      }, 8, ["onClick", "onKeydown"]),
      _createVNode(_component_AppButton, {
        id: "view-statistics",
        class: "is-accent",
        onClick: _ctx.toStatistics,
        onKeydown: _withKeys(_withModifiers(_ctx.toStatistics, ["prevent"]), ["space","enter"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('label.rekionAdminViewStatistics')), 1)
        ]),
        _: 1
      }, 8, ["onClick", "onKeydown"]),
      _createVNode(_component_AppButton, {
        id: "back-to-top",
        class: "is-normal",
        onClick: _ctx.back,
        onKeydown: _withKeys(_withModifiers(_ctx.back, ["prevent"]), ["space","enter"])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('label.rekionAdminBackToTop')), 1)
        ]),
        _: 1
      }, 8, ["onClick", "onKeydown"])
    ])
  ]))
}